<template>
  <div class="P-client-payments">
    <div
      class="G-page-component"
      :class="{
        'P-filter-open': isOpenFilter,
        'pa-0': $route.name === 'Search',
      }"
    >
      <div
        :class="{ 'mb-0': $route.name === 'Search' }"
        class="G-page-title-block G-flex G-justify-between G-align-center"
      >
        <div class="G-component-title">
          <h3
            :class="{ 'P-small-header': $route.name === 'Search' }"
            class="secondary--text"
          >
            Client Bills
          </h3>
        </div>
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="selectedArrowList.length || selectedArrowListSub.length"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectMultipleAction('sendNow')"
                  >
                    <span class="icon-send neutral--text P-icon" />
                  </div>
                </template>
                <span>Send Now</span>
              </v-tooltip>
              <v-tooltip
                v-if="filterModel.sentUnsent !== false"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectMultipleAction('cancelNow')"
                  >
                    <span class="mdi mdi-cancel neutral--text P-icon" />
                  </div>
                </template>
                <span>Cancel Now</span>
              </v-tooltip>
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectMultipleAction('viewBills')"
                  >
                  <span class="mdi mdi-format-list-bulleted neutral--text P-icon"></span>
                  </div>
                </template>
                <span>View Bills</span>
              </v-tooltip>
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="showWarningModal = true"
                  >
                    <span class="icon-printer-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Print</span>
              </v-tooltip>
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="P-settings"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportData"
                >
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            v-if="showColumnConfig"
            :column-config="columnConfigData"
            :is-default="isDefault"
            class="P-users-columns"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @resetDataSettingsTable="resetDataTableSettings"
            @hoverColumnConfig="hoverColumnConfig"
            @leaveColumnConfig="leaveColumnConfig"
          />
        </div>
        <div v-if="$route.name !== 'Search'">
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              class="active-filter-count"
              v-if="filterModel.filterCount.length > 0"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div class="G-component-body page-background">
        <div
          v-if="$route.name !== 'Search'"
          class="G-justify-between align-center G-sub-block-title"
        >
          <div v-if="$route.name !== 'Search'">
            <h3 class="secondary--text">Client Bill List</h3>
          </div>

          <div class="G-align-center">
            <transition name="fade">
              <div class="G-total-sum G-flex-center px-3 me-2" v-if="postalMethodsBalance !== null">
                Postal Methods Balance
                {{
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(postalMethodsBalance)
                }}
              </div>
            </transition>
            <transition name="fade">
              <div
                v-if="sentFromFilter !== null && sentFromFilter"
                class="G-total-sum G-flex-center px-3 me-2"
              >
                Sent {{ sentFromFilter }}
              </div>

              <div
                v-if="sentFromFilter === null && sentFromSocket"
                class="G-total-sum G-flex-center px-3 me-2"
              >
                Sent {{ sentFromSocket }}
              </div>
            </transition>

            <transition name="fade">
              <div
                v-if="queueFromFilter !== null && queueFromFilter !== 0"
                class="G-total-sum G-flex-center px-3 me-2"
              >
                Not Sent {{ queueFromFilter }}
              </div>

              <div
                v-if="queueFromFilter === null && queueFromSocket !== null && queueFromFilter !== 0"
                class="G-total-sum G-flex-center px-3 me-2"
              >
                Not Sent {{ queueFromSocket }}
              </div>
            </transition>

            <transition name="fade">
              <div
                v-if="autoSendMilliseconds"
                class="G-total-sum G-flex-center px-3 me-2"
              >
                Auto Send After {{ hours }} : {{ minutes }} : {{ seconds }}
              </div>
            </transition>

            <div class="G-total-sum G-flex-center px-3">Total Sum {{ new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(totalSum) }}</div>
          </div>
        </div>

        <OttUniversalTable
          v-if="
            (!loading && $route.name !== 'Search') || $route.name === 'Search'
          "
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        >
          <template
            v-if="permissionIs('Invoices') !== 'viewOnly'"
            slot-scope="props"
            slot="action"
          >
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in actionsList(
                    props.rowData.state,
                    props.rowData
                  )"
                  :key="index"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-if="clientBillsModule.clientBillsResult && !loading"
          v-model="rowsPerPage"
          :data-count="clientBillsModule.dataCount"
          :available-data-count="dataTable.length"
          :current-page="clientBillsModule.currentPage"
          :page-limit="clientBillsModule.totalPages"
          @update="updateLimit"
          @updatePage="updateClientBillsList"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      v-if="$route.name !== 'Search'"
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          :click="resetData"
          class="primary-lg-btn"
          text="Reset Filters"
        >
        </ott-button>
      </div>
    </div>
    <CheckoutClientModal v-if="isOpenCheckoutModal" />
    <PageLoader
      v-if="(isTableLoading || loading) && $route.name !== 'Search'"
    />
    <ManageClient v-if="clients.modal.isOpen" />
    <warning-modal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :isDangerButton="false"
      :isLoading="warningModalIsLoading"
      @btnFunction="warningModalBtnFunction"
      @closeModal="showWarningModal = false"
      btnText="Print Invoices"
    />
  </div>
</template>

<script>
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
import FilterData from "./components/FilterData";
import ClientBillsMixin from "./ClientBillsMixin";
import CreditCardsMixin from "@/mixins/CreditCardsMixin";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import CheckoutClientModal from "@/views/clients/modals/checkout/CheckoutClientModal";
import ManageClient from "../clients/modals/manageClient/ManageClient";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import WarningModal from "@/components/WarningModal";
import Transactions from "../transactions/Transactions.vue";

export default {
  components: {
    OttSelect,
    ManageClient,
    OttRadio,
    OttSwitch,
    FilterData,
    PageLoader,
    NoDataInTable,
    OttPaginationForTable,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttButton,
    CheckoutClientModal,
    WarningModal,
  },
  mixins: [
    ClientBillsMixin,
    OttUniversalTableForComponentsMixin,
    FilterMixin,
    ColumnConfigMixin,
    CreditCardsMixin
  ],
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-users-columns {
  padding-left: 10px;
}

.P-filter-open {
  .mdi-menu-right {
    transform: rotate(180deg);
  }
}

.P-client-payments {
  position: relative;
}

.approve-rej-icon-cont {
  font-size: 24px;

  .mdi {
    padding: 0 7px;
    display: inline-block;
  }

  .mdi-cancel,
  .mdi-check-circle-outline {
    border-radius: $border-radius;
  }
}

.approve-rej-icon-cont::v-deep {
  .v-tooltip__content {
    border-radius: 6px;
    padding: 7px 9px;
    font-size: $txt12;
  }
}

.select-all-menu-cont {
  .P-icon {
    font-size: 26px !important;
  }

  ::v-deep {
    .v-menu__content {
      max-width: 190px !important;
    }
  }
}

.select-all-menu-cont button .mdi-dots-vertical,
.settings-btn button .mdi-settings-outline {
  height: 38px;
  font-size: 24px;
  width: 38px;
  border-radius: 4px;
}

.theme--light {
  .active-filter-count {
    background-color: #e5f5f6 !important;
    color: $primary-color !important;
  }

  .action-cont-first-cont::v-deep {
    .v-icon {
      color: $neutral-color;
    }

    .mdi-minus-box {
      color: $primary-color !important;
    }
  }

  .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
  .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
    background: $neutral-color15;
    color: $secondary-color !important;
  }

  .select-all-menu-cont button .mdi-dots-vertical:hover,
  .settings-btn button .mdi-settings-outline:hover {
    background: $neutral-color15;
    color: $secondary-color !important;
  }

  .icon-active {
    background: $neutral-color;
    color: $secondary-color !important;
    border-radius: $border-radius;
  }
}

.theme--dark {
  .action-cont-first-cont::v-deep {
    .v-icon {
      color: $neutral-colorDM !important;

      &.v-icon:after {
        opacity: 0;
      }
    }

    .mdi-minus-box {
      color: $primary-colorDM !important;
    }

    .primary--text {
      color: $primary-colorDM !important;
    }
  }

  .action-cont-first {
    border-right: 1.5px solid $borderDM;
  }
}

.G-table-actions {
  .v-menu__content {
    max-height: inherit !important;
  }
}

.v-menu__content.P-actions-list {
  max-height: inherit !important;
}

.G-excel-btn {
  border-left: unset;
  padding-left: unset;
}

.action-cont-first {
  display: flex;
  border-right: 1.5px solid $neutral-color;
  height: 36px;
  align-items: center;

  .v-input--selection-controls {
    margin-top: 23px;
  }
}

.P-icon {
  position: relative;
  z-index: 5;
  transition: $transition;
}

.P-settings {
  .P-icon {
    font-size: 22px;
  }
}

.P-bills-switch {
  margin: 0 15px;
  padding: 0 15px;
  border-left: 1.5px solid $neutral-color;
  border-right: 1.5px solid $neutral-color;

  &::v-deep {
    .P-text {
      white-space: nowrap;
    }

    .v-input {
      width: max-content !important;
      margin-top: 0;
      padding: 10px 0;
    }
  }
}
.P-radio {
  margin-top: 0;
  padding: 10px 0;
}
.P-radio-box + .P-radio-box {
  margin-left: 15px;
}

.G-table-actions {
  .Ott-select-cont {
    width: 200px;
    margin-left: 15px;
  }
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}

.P-small-header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.cursor-none {
  & button {
    cursor: unset !important;
  }
}
</style>
