<template>
  <selected-filters
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "SelectedFiltersList",
  components: { SelectedFilters },
  mixins: [ DateFormatMixin ],

  computed: {
    ...mapState({
      filterModel: (state) => state.clientBillsModule.filterModel,
      filterData: state => state.clientBillsModule.filterData,
      clientBillsModule: (state) => state.clientBillsModule,
      resellersList: state => state.providers.resellersList,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: 'search'
        },
        {
          ifCondition: this.filterModel.sentUnsent !== null,
          boxText: `<b>Sent/Not Sent</b>: ${
            this.filterModel.sentUnsent === 'all' ? 'All' :
            this.filterModel.sentUnsent === true ? 'Sent' :
            this.filterModel.sentUnsent === false ? 'Not Sent' : ''
          }`,
          resetArgument: 'sentUnsent'
        },
        {
          ifCondition: this.filterModel.providerId,
          boxText: `<b>Provider</b>: ${this.getProviderName}`,
          resetArgument: 'providerId'
        },
        {
          ifCondition:
          !!(
            this.filterModel.subscriptionAmount[0] ||
            this.filterModel.subscriptionAmount[1]
          ),
          boxText: `<b>Subscriptions Amount</b>: ${this.getSubscriptionAmountValue}`,
          resetArgument: 'subscriptionAmount'
        },
        {
          ifCondition: !!(this.filterModel.totalAmount[0] || this.filterModel.totalAmount[1]),
          boxText: `<b>Total Amount</b>: ${this.getTotalAmountValue}`,
          resetArgument: 'totalAmount'
        },
        {
          ifCondition: this.filterModel.dateForPay?.start && this.filterModel.dateForPay?.end,
          boxText: `<b>Date For Pay</b>: ${this.getDate(this.filterModel.dateForPay?.start)} - ${this.getDate(this.filterModel.dateForPay?.end)}`,
          resetArgument: 'dateForPay'
        },
        {
          ifCondition: !!this.filterModel.autoPayment,
          boxText: '<b>Auto Payment</b>: ' + this.getSelectedData('autoPayment'),
          resetArgument: 'autoPayment'
        },
        {
          ifCondition: !!this.filterModel.paymentStatus.length,
          boxText: '<b>Payment Status</b>: ' + this.getSelectedData('paymentStatus'),
          resetArgument: 'paymentStatus'
        },
        {
          ifCondition: !!this.filterModel.paymentActonBy,
          boxText: '<b>Payment Acton By</b>: ' + this.getSelectedData('paymentActonBy'),
          resetArgument: 'paymentActonBy'
        },
        {
          ifCondition: !!this.filterModel.paymentMethod.length,
          boxText: '<b>Payment Method</b>: ' + this.getSelectedData('paymentMethod'),
          resetArgument: 'paymentMethod'
        },
        {
          ifCondition: this.filterModel.dateForPayment?.start && this.filterModel.dateForPayment?.end,
          boxText: '<b>Date For Payment</b>: ' + this.getDate(this.filterModel.dateForPayment?.start) + ' - ' + this.getDate(this.filterModel.dateForPayment?.end),
          resetArgument: 'dateForPayment'
        },
        {
          ifCondition: !!this.filterModel.billSentMethod.length,
          boxText: '<b>Bill Sent Method</b>: ' + this.getSelectedData('billSentMethod'),
          resetArgument: 'billSentMethod'
        },
        {
          ifCondition: this.filterModel.dateForBillSent?.start && this.filterModel.dateForBillSent?.end,
          boxText: `<b>Date For Bill Sent</b>: 
          ${this.getDate(this.filterModel.dateForBillSent?.start)} - 
          ${this.getDate(this.filterModel.dateForBillSent?.end)}`,
          resetArgument: 'dateForBillSent'
        },
      ]
    },

    getProviderName() {
      let providerName = ''
      if (this.resellersList.length) {
        this.resellersList.map((i) => {
          if(i.id === this.filterModel.providerId) {
            providerName = i.name
          }
        })
      }

      return providerName;
    },


    getSubscriptionAmountValue() {
      let value = ''
      if (
        this.filterModel.subscriptionAmount[0] ||
        this.filterModel.subscriptionAmount[1]
      ) {
        value =
          this.filterModel.subscriptionAmount[0] +
          " - " +
          this.filterModel.subscriptionAmount[1];
      }
      return value
    },

    getTotalAmountValue() {
      let value = ''
      if (this.filterModel.totalAmount[0] || this.filterModel.totalAmount[1]) {
        value = 
          this.filterModel.totalAmount[0] +
          " - " +
          this.filterModel.totalAmount[1];
      }
      return value
    },
  },

  methods: {
    ...mapActions({
      getBillsList: 'clientBillsModule/getBillsList',
    }),

    ...mapMutations({
      filterClientBills: 'clientBillsModule/filterClientBills',
      setFilterPage: 'clientBillsModule/setFilterPage',
      sortColumn: 'clientBillsModule/sortColumn',
    }),

    async resetData(type) {
      this.setFilterPage(1)
      this.filterModel.resetCurrentData(type);
      this.filterClientBills()
      this.filterModel.getFilterCount();
      this.sortColumn()
      await this.getBillsList(this.filterData)
    },

    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },

    getSelectedData(type) {
      switch (type) {
        case "autoPayment": {
          if (this.filterModel.autoPayment) {
            return this.clientBillsModule.autoPaymentList.filter(
              (x) => x.value === this.filterModel.autoPayment
            )[0].name;
          }
          break;
        }
        case "paymentStatus": {
          let list = this.clientBillsModule.paymentStatusList.filter((x) =>
            this.filterModel.paymentStatus.includes(x.value)
          );
          return this.generateNameFromArray(list);
        }
        case "paymentActonBy": {
          if (this.filterModel.paymentActonBy) {
            return this.clientBillsModule.paymentActonByList.filter(
              (x) => x.value === this.filterModel.paymentActonBy
            )[0].name;
          }
          break;
        }
        case "paymentMethod": {
          let list = this.clientBillsModule.paymentMethodList.filter((x) =>
            this.filterModel.paymentMethod.includes(x.value)
          );
          return this.generateNameFromArray(list);
        }
        case "billSentMethod": {
          let list = this.clientBillsModule.billSentMethodList.filter((x) =>
            this.filterModel.billSentMethod.includes(x.value)
          );
          return this.generateNameFromArray(list);
        }
      }
    },

    generateNameFromArray(list) {
      let x = "";
      if (list.length) {
        list.forEach((item, index) => {
          x += item.name + (index !== list.length - 1 ? ", " : "");
        });
        return x;
      }
    },
  },
};
</script>
