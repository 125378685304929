import { mapActions, mapMutations, mapState } from "vuex";
import { eventBus } from "@/main";
import _ from "lodash";

export default {

  name: 'ClientBills',

  data() {
    return {
      intervalId: null,   // Interval ID for the timer
      hours: 0,
      minutes: 0,
      seconds: 0,
      // isLoading: false,
      columnConfigData: [],
      copyColumnConfigData: [],
      defaultColumnConfigData: [],
      actions: [
        {
          title: 'View Bill',
          value: 'viewBill'
        },
        {
          title: 'Edit Client',
          value: 'editClient'
        },
        {
          title: 'Client Orders',
          value: 'clientOrders'
        },
        {
          title: 'Pay',
          value: 'pay'
        },
        {
          title: 'Refund',
          value: 'refund'
        },
        {
          title: 'Change Send Method',
          value: 'changeSendMethod'
        },
        {
          title: 'Sent History',
          value: 'sentHistory'
        },
        {
          title: 'Separate Credit/Subscr Bills',
          value: 'separateCredit'
        },
        {
          title: 'Send Now',
          value: 'sendNow'
        },
        {
          title: 'Cancel Now',
          value: 'cancelNow'
        }
      ],

      showWarningModal: false,
      warningModalIsLoading: false,
      loading: false
    }
  },

  computed: {
    ...mapState({
      filterModel: state => state.clientBillsModule.filterModel,
      clientBillsModule: state => state.clientBillsModule,
      isOpenCheckoutModal: state => state.checkoutClients.modal.isOpen,

      // important variables vor get list and  change  columns
      filterData: state => state.clientBillsModule.filterData,
      filter: state => state.clientBillsModule.filter,
      dataTable: state => state.clientBillsModule.dataTable,
      defaultSettings: state => state.clientBillsModule.defaultSettings,
      updateColumnSettingsCommit: state => state.clientBillsModule.updateColumnSettingsCommit,
      updateColumnsFromBack: state => state.clientBillsModule.updateColumnsFromBack,
      isDefault: state => state.clientBillsModule.isDefault,
      clients: state => state.clientsModule,
      showColumnConfig: state => state.clientBillsModule.showColumnConfig,
      isTableLoading: state => state.clientBillsModule.isTableLoading,
      paymentForPackagesObj: state => state.financeClient.paymentForPackagesObj,
      sentFromFilter: state => state.clientBillsModule.sent,
      queueFromFilter: state => state.clientBillsModule.queue,
      totalSum: state => state.clientBillsModule.totalSum,
      postalMethodsBalance: state => state.clientBillsModule.postalMethodsBalance     
    }),
    
    autoSendMilliseconds: {
      set(autoSendMilliseconds) {
        this.setData({ autoSendMilliseconds })
      },
      get() {
        return this.clientBillsModule.autoSendMilliseconds
      }
    },
    sentFromSocket: {
      set(sentFromSocket) {
        this.setData({ sentFromSocket })
      },
      get() {
        return this.clientBillsModule.sentFromSocket
      }
    },
    queueFromSocket: {
      set(queueFromSocket) {
        this.setData({ queueFromSocket })
      },
      get() {
        return this.clientBillsModule.queueFromSocket
      }
    },

    warningModalHeading() {
      return `Print Invoice(s)?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>print</ins></span> one or more Invoices! <br />  If you're not sure, you can cancel this operation.`
    },

    defaultColumnConfigs() {
      return [
        {
          title: "Bill Number",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "billNumber",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.number ? row.number : '-'}</p>`
        },
        {
          title: "Client ID",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "clientId",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.client?.number_id ? row.client?.number_id : '-'}</p>`
        },
        {
          title: "Client Name",
          isTranslate: false,
          style: { minWidth: 230 },
          key: "clientName",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row?.client ? row?.client?.personalInfo?.firstname + ' ' + row?.client?.personalInfo?.lastname : ''}</p>`
        },
        {
          title: "Client Email",
          isTranslate: false,
          style: { minWidth: 230 },
          key: "clientEmail",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row?.client && row?.client?.emails.length ? this.getEmail(row?.client.emails) : '-'}</p>`
        },
        {
          title: "Client Phone",
          isTranslate: false,
          style: { minWidth: 210 },
          key: "clientPhone",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.client && row.client.phones.length ? this.getPhoneNumber(row.client.phones) : '-'}</p>`
        },
        {
          title: "Client Address",
          isTranslate: false,
          style: { minWidth: 220 },
          key: "clientAddress",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.client && row.client.addresses ? this.getAddress(row.client.addresses) : '-'}</p>`
        },
        {
          title: "Subscription Expire Date",
          isTranslate: false,
          style: {minWidth: 200},
          key: "expireDate",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.generateDisplayInfo?.subscriptionEndDate ? row.generateDisplayInfo?.subscriptionEndDate : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "generateDisplayInfo.subscriptionEndDate",
            sortValue: ""
          },
        },
        {
          title: "Print Date",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "printDate",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.sent ? row.updatedAt : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "updatedAt",
            sortValue: ""
          }
        },
        {
          title: "Current Balance",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "balance",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">
            ${row.client.length ? this.numberToFinanceNumber(row.client?.balance) : '-'}
          </p>`,
          sortData: {
            isSort: true,
            sortKey: "currentBalance",
            sortValue: ""
          }
        },
        {
          title: "Current Debt",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "debt",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 9,
          mainIndex: 9,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">
            ${row.client.length ? this.numberToFinanceNumber(row.client?.debt) : '-'}
          </p>`,
          sortData: {
            isSort: true,
            sortKey: "currentDebt",
            sortValue: ""
          }
        },
        {
          title: "Current Credit",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "credit",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 10,
          mainIndex: 10,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${
            ('currentCredit' in row?.client) 
              ? this.numberToFinanceNumber(row?.client?.currentCredit)
              : '-'
          }</p>`,
          sortData: {
            isSort: true,
            sortKey: "currentCredit",
            sortValue: ""
          }
        },
        {
          title: "Credit Start",
          isTranslate: false,
          style: { minWidth: 120 },
          key: "creditStart",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 11,
          mainIndex: 11,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row?.client.creditStart ? row?.client.creditStart : '-'}</p>`
  
        },
        {
          title: "Credit Expire",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "creditExpire",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 12,
          mainIndex: 12,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row?.client.creditExpireDate ? row.client.creditExpireDate : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "creditExpire",
            sortValue: ""
          },
        },
        // {
        //   title: "Bill Info Type",
        //   isTranslate: false,
        //   style: { minWidth: 150 },
        //   key: "billInfoType",
        //   isShow: true,
        //   isDrag: true,
        //   defaultDragIndex: 13,
        //   mainIndex: 13,
        //   customUnshow: false,
        //   disabled: false,
        //   cellView: row => `<p class="G-text-table">${this.getTypes(row.billInfoType, 'billInfoType')}</p>`,
        //   sortData: {
        //     isSort: true,
        //     sortKey: "billInfoType",
        //     sortValue: ""
        //   },
        // },
        {
          title: "Logins (Locations)",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "locations",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 14,
          mainIndex: 14,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${this.getParticipantLogins(row?.payloadCalculated?.locations)}</p>` // logins list
        },
        {
          title: "Subscription Amount",
          isTranslate: false,
          style: { minWidth: 170 },
          key: "subscriptionAmount",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 15,
          mainIndex: 15,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${
            ('totalPrice' in row?.payloadCalculated)
              ? this.numberToFinanceNumber(row?.payloadCalculated?.totalPrice, { redText: row.state === 2 || row.state === 4 || row.state === 6 ? true : false }) 
              : '-'}
          </p>`,
          sortData: {
            isSort: true,
            sortKey: "subscriptionAmount",
            sortValue: ""
          },
        },
        {
          title: "Total Amount",
          isTranslate: false,
          style: { minWidth: 140 },
          key: "totalAmount",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 16,
          mainIndex: 16,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">
            ${this.numberToFinanceNumber(row.amount, { redText: row.state === 2 || row.state === 4 || row.state === 6 ? true : false })}
          </p>`,
          sortData: {
            isSort: true,
            sortKey: "totalAmount",
            sortValue: ""
          },
        },
        {
          title: "Time for Pay",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "timeForPay",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 17,
          mainIndex: 17,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.timeForPay ? row.timeForPay : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "timeForPay",
            sortValue: ""
          },
        },
        {
          title: "Auto Payment",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "autoPayment",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 18,
          mainIndex: 18,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table P-checked">${row.autoPayment ? `<span class="mdi mdi-check"></span>` : ""}</p>`,
          sortData: {
            isSort: true,
            sortKey: "autoPayment",
            sortValue: ""
          },
        },
        {
          title: "Payment Status",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "paymentStatus",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 19,
          mainIndex: 19,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${this.getPaymentStatus(row.state)}</p>`,
          sortData: {
            isSort: true,
            sortKey: "paymentStatus",
            sortValue: ""
          },
        },
        {
          title: "TransactionId",
          isTranslate: false,
          style: { minWidth: 170 },
          key: "transactionId",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 20,
          mainIndex: 20,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.transaction ? this.getTransactionId(row.transaction) : '-'}</p>`
        },
        {
          title: "Payment/Action By",
          isTranslate: false,
          style: { minWidth: 180 },
          key: "paymentActionBy",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 21,
          mainIndex: 21,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${this.getTypes(row.from_type, 'paymentActonBy')}</p>`,
          sortData: {
            isSort: true,
            sortKey: "paymentActionBy",
            sortValue: ""
          },
        },
        // {
        //   title: "Provider Name",
        //   isTranslate: false,
        //   style: { minWidth: 180 },
        //   key: "providerName",
        //   isShow: true,
        //   isDrag: true,
        //   defaultDragIndex: 22,
        //   mainIndex: 22,
        //customUnshow: false,
        //disabled: false,
        //   cellView: row => `<p class="G-text-table">${row.providerName ? row.providerName : '-'}</p>`
        // },
        // {
        //   title: "User Name",
        //   isTranslate: false,
        //   style: { minWidth: 180 },
        //   isShow: true,
        //   isDrag: true,
        //   defaultDragIndex: 23,
        //   mainIndex: 23,
        //customUnshow: false,
        //disabled: false,
        //   cellView: row => `<p class="G-text-table">${row?.userFirstName && row?.userLastName ? row?.userFirstName + ' ' + row?.userLastName : '-'}</p>`
        // },
        {
          title: "Payment/Action Time",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "paymentActionTime",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 24,
          mainIndex: 24,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row.paymentActionTime ? row?.paymentActionTime : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "paymentActionTime",
            sortValue: ""
          },
        },
        {
          title: "Bill (Will) Sent Method",
          isTranslate: false,
          style: { minWidth: 180 },
          key: "billSentMethod",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 26,
          mainIndex: 26,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${this.getTypes(row?.sentType, 'billSentMethod')} ${row?.postalMethodId ? row.postalMethodId : ''} ${row?.postalMethodStatus ? row.postalMethodStatus : ''}</p>`,
          sortData: {
            isSort: true,
            sortKey: "sentType",
            sortValue: ""
          },
        },
        {
          title: "Last Sent Time",
          isTranslate: false,
          style: { minWidth: 150 },
          key: "lastSentTime",
          isShow: true,
          isDrag: true,
          defaultDragIndex: 27,
          mainIndex: 27,
          customUnshow: false,
          disabled: false,
          cellView: row => `<p class="G-text-table">${row?.lastSentTime ? row?.lastSentTime : '-'}</p>`,
          sortData: {
            isSort: true,
            sortKey: "lastSentTime",
            sortValue: ""
          },
        },
      ];
    },

    rowsPerPage: {
      set(limit) {
        if (this.$route.name !== 'Search')
          this.setLimit(limit)
        else 
          this.setSearchLimit(limit)
      },
      get() {
        if (this.$route.name !== 'Search')
          return this.filter.limit;
        else  
          return this.filter.searchLimit;
      }
    },

  },

  watch: {
    'filterModel.sentUnsent'() {
      
      // this.setFilterPage(1)
      this.hideShowColumns()
      // await this.getBillListWrapper(this.filterData)
      // this.resetSelectedList()
    },
    
  //   columnConfigData: {
  //     deep: true,
  //     handler(newData, oldData) {
  //       if (newData.length && !oldData.length) {
  //         this.copyColumnConfigData = _.clone(newData)
  //       }
  //     }
  //   }
  },

  async created() {
    this.updateTimer();
    this.startTimer();

    this.sortColumn();
    if (this.$route.name !== 'Search') {
      this.loading = true
      await this.getClientBillsSettings()
    }

    if (this.defaultSettings.length) {
      this.showHiddenColumns()
    }

    this.createColumnConfigData()

    if (this.$route.name !== 'Search') {
      await this.getBillListWrapper(this.filterData).finally(() => this.loading = false)
      await this.getResellersList()
      this.resetSelectedList()
      
      // After closing client modal, doing Bills Get API
      eventBus.$on('clientModalDestroyed', async () => {
        if (this.permissionIs('getBillInvoices')) {
          await this.getBillListWrapper(this.filterData)
        }
      })
    }

    await this.getBillStatisticInfo()

    this.$hubEventBus.$on("invoice-schedule-info", this.handlePostalScheduleInfo); // if loggedIn get permissions with WS
    this.$hubEventBus.$on("invoice-info", this.handleInvoiceInfo); // if loggedIn get permissions with WS
  },

  beforeDestroy() {
    this.$hubEventBus.$off("invoice-schedule-info", this.handlePostalScheduleInfo); // if loggedIn get permissions with WS
    this.$hubEventBus.$off("invoice-info", this.handleInvoiceInfo); // if loggedIn get permissions with WS

    this.filterModel.reset()
    this.setData({ filterData: {} })
  },

  destroyed() {
    this.setFilterPage(1)
    this.billsResetState()
  },

  methods: {
    async handlePostalScheduleInfo(scheduleInfo) {
      if (this.filterModel.providerId === scheduleInfo.provider) {
        this.autoSendMilliseconds = scheduleInfo.postalInoviceNextSchedule;
        this.sentFromSocket = scheduleInfo.sent
        this.queueFromSocket = scheduleInfo.inQueue

        this.updateTimer();
        this.startTimer();
        console.log('invoice-schedule-info updated');
      } else {
        console.log('invoice-schedule-info not updated');
      }
    },
    async handleInvoiceInfo(invoiceInfo) {
      //After send invoice, create toast for show message
      this.createToastMessage({
        message: invoiceInfo.message,
        success: invoiceInfo.status,
      })
      if (invoiceInfo.status) {
        await this.getBillListWrapper(this.filterData)
        this.resetSelectedList()
      }
    },
    ...mapActions({
      getBillsList: 'clientBillsModule/getBillsList',
      getInvoice: 'clientBillsModule/getInvoice',
      getClientBillsSettings: 'clientBillsModule/getClientBillsSettings',
      getClientData: 'clientsModule/getClientData',
      setPersonalInfo: 'personalInfoClient/setPersonalInfo',
      setContactData: 'contactClient/setContactData',
      setAddresses: 'addressClient/setAddresses',
      getClientPaymentMethod: 'paymentMethodClients/getPaymentMethod',
      getBalanceCredit: 'balanceCreditClient/getBalanceCredit',
      getLocations: 'locationClient/getLocations',
      getResellersList: 'providers/getResellersList',
      viewBills: 'clientBillsModule/viewBills',
      printBills: 'clientBillsModule/printBills',
      sendIncoices: 'clientBillsModule/sendIncoices',
      cancelIncoices: 'clientBillsModule/cancelIncoices',
      getBillStatisticInfo: 'clientBillsModule/getBillStatisticInfo'
    }),
    ...mapMutations({
      setData: 'clientBillsModule/setData',
      setLimit: 'clientBillsModule/setLimit',
      setSearchLimit: 'clientBillsModule/setSearchLimit',
      sortColumn: 'clientBillsModule/sortColumn',
      setFilterPage: 'clientBillsModule/setFilterPage',
      openCheckoutModal: 'checkoutClients/openModal',
      filterClientBills: 'clientBillsModule/filterClientBills',
      billsResetState: 'clientBillsModule/resetState',
      setDisableButton: 'clientsModule/setDisableButton',
      personalInfoReset: 'personalInfoClient/resetData',
      setDataClient: 'clientsModule/setData',
      setContactMutation: 'contactClient/setData',
      resetBalanceManage: 'balanceCreditClient/resetBalanceManage',
      resetCreditData: 'balanceCreditClient/resetCreditData',
      balanceCreditSetData: 'balanceCreditClient/setData',
      setFinanceFormData: 'financeClient/setFormData',
      financeSetData: 'financeClient/setData',
      financeResetState: "financeClient/resetState",
      openClientsModal: 'clientsModule/openModal',
      setShowSaveBtn: 'personalInfoClient/setShowSaveBtn',
      setIsAddClient: 'clientsModule/setIsAddClient',
      setClientId: 'clientsModule/setClientId',
      setBillNumber: 'clientsModule/setBillNumber',
      transactionsSetData: "transactionsModule/setData",
      shippingsSetData: "shippingsModule/setData",
      clientActivitySetData: "clientActivityModule/setData",
      resetDisableTabs: "clientsModule/resetDisableTabs"
    }),

    // Update the timer values from autoSendMilliseconds
    updateTimer() {
      const totalSeconds = Math.floor(this.autoSendMilliseconds / 1000);
      this.hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
      this.minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
      this.seconds = String(totalSeconds % 60).padStart(2, '0');
    },

    // Start the countdown
    startTimer() {
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        if (this.autoSendMilliseconds > 0) {
          this.autoSendMilliseconds -= 1000;
          this.updateTimer();
        }
      }, 1000);
    },

    // Stop the countdown
    stopTimer() {
      clearInterval(this.intervalId);
    },
  
    async warningModalBtnFunction() {
      this.warningModalIsLoading = true
      await this.selectMultipleAction('print').finally(() => {
        this.warningModalIsLoading = false;
      });
      this.showWarningModal = false;
    },


    actionsList(state, rowData) {
      let actions = {}
      const postalMethodId = rowData?.postalMethodId;
      switch(state) {
        case 1:
          actions = this.actions.filter((item) => item.value !== 'pay')
        case 2:
        case 3:
        case 4:
          actions = this.actions.filter((item) => item.value !== 'refund')
        case 6:
          actions = this.actions.filter((item) => item.value !== 'refund' && item.value !== 'pay' && item.value !== 'cancelNow')
        default:
          actions = this.actions;
      }

      if (rowData?.sent) {
        actions = this.actions.filter((item) => item.value !== 'sendNow')
      } else {
        actions = this.actions.filter((item) => item.value !== 'cancelNow')
      }

      return actions;
    },

    async getBillListWrapper(data) {
      await this.getBillsList(data);
      this.updateTimer();
      this.startTimer();
    },

    async resetData() {
      this.filterModel.reset();
      this.filterClientBills()
      await this.getBillListWrapper(this.filterData)
      this.resetSelectedList()
    },

    async selectMultipleAction(action) {
      let selectedBills = []
      this.selectedArrowList.map(item => {
        selectedBills.push(item.id)
      })

      switch (action) {
        case 'viewBills':
          await this.viewBills(selectedBills)
          break;
        case 'print':
          await this.printBills(selectedBills);
          //After send invoice, create toast for show message
          this.createToastMessage({
            message: 'Bills Invoices Printed',
            success: true,
          })
          this.showWarningModal = false
          await this.getBillListWrapper(this.filterData)
          this.resetSelectedList()
          selectedBills = []

          break;
        case 'sendNow':
          await this.sendIncoices(selectedBills).then(response => {
            //After send invoice, create toast for show message
            this.createToastMessage( { success: true, message: response.message})
          }).catch((response) => {
            //After send invoice, create toast for show message
            this.createToastMessage({ success: false, message: response?.response?.data?.message ?? response.message})
          })
          break;
        case 'cancelNow':
          await this.cancelIncoices(selectedBills).then(response => {
            //After cancel invoice, create toast for show message
            this.createToastMessage( { success: true, message: response.message})
          }).catch((response) => {
            //After cancel invoice, create toast for show message
            this.createToastMessage({ success: false, message: response?.response?.data?.message ?? response.message})
          })
          break;
      }
    },

    /**
     * Update Client bills list
     */
    async updateClientBillsList(page, showLoader = true) {
      if (showLoader) {
        this.setData({ isDataTableLoading: true })
      }
      if (page) {
        this.setFilterPage(page)
      }
      this.sortColumn();
      await this.getBillListWrapper(this.filterData)
      this.resetSelectedList()
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      this.sortColumn();
      await this.getBillListWrapper(this.filterData)
      this.resetSelectedList()
    },

    getTypes(data, type) {
      switch (type) {
        case 'paymentActonBy': {
          // return 'this.usersActivityModule.paymentActonByList.filter(x => x.value === data)[0].name'
          return data === 1 ? 'Provider' : 'Client'
        }
        case 'billSentMethod': {
          // return 'this.usersActivityModule.billSentMethodList.filter(x => x.value === data)[0].name'
          switch(data) {
            case 'postal':
              return 'Postal Methods';
            case 'print':
              return 'Print PDF'
            case 'email':
              return 'Email'
            default:
              return 'N/A';
          } 
        }
        case 'paymentMethod': {
          // console.log(' ')
          // return 'this.usersActivityModule.paymentMethodList.filter(x => x.value === data)[0].name'
          return 'paymentMethod'
        }
      }
    },
    getPaymentMethod(payments) {
      // data will change
      if (payments && payments.length) {
        let last = payments[payments.length - 1]
        let paymentBrand = "";

        if (last.creditCard !== undefined) {
          paymentBrand = last.creditCard.brand
        }
        if (last.paymentMethod === 1) {
          paymentBrand = 'bankAccount'
        }

        return `<div class="G-text-table G-flex G-align-center">
                    <div class="P-card-brand" style="background-image: url('${this.getCardBrand(paymentBrand)}')"></div>
                    <span>${last.creditCard.cardNumber.substring(0, 4)}</span>
                </div>`
      } else {
        return '-'
      }
    },
    getEmail(emails) {
      if (emails.length) {
        let isMain = emails.filter(email => email.isMain)[0]
        if (isMain) {
          return isMain.email
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getPhoneNumber(phones) {
      if (phones.length) {
        let forCall = phones.filter(phone => phone.forCall)[0]
        if (forCall) {
          return forCall.phone
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getAddress(addresses) {
      let address = ''
      if (addresses.length) {
        let forContactAddress = addresses.filter(a => a.forContactInvoice)[0]

        if (forContactAddress) {
          address += this.FormatClientAddress(forContactAddress);
          return address
        }

        if (!forContactAddress) {
          address += this.FormatClientAddress(addresses[0]);
          return address
        }
      } else {
        return '-'
      }
    },
    getPaymentStatus(value) {
      switch (value) {
        case 1: {
          return '<span class="G-badge G-approve-badge G-flex G-align-center">Paid</span>'
        }
        case 2: {
          return '<span class="G-badge G-reject-badge G-flex G-align-center">Unpaid</span>'
        }
        case 3: {
          return '<span class="G-badge G-blocked-badge G-flex G-align-center">Refunded</span>'
        }
        case 4: {
          return '<span class="G-badge G-reject-badge G-flex G-align-center">Unpaid</span>'
        }
        case 5: {
          return '<span class="G-badge G-pending-badge G-flex G-align-center">Upcoming</span>'
        }
        case 6: {
          return '<span class="G-badge G-declined-badge G-flex G-align-center">Declined</span>'
        }
      }
    },
    getParticipantLogins(data) {
      if (data && data.length) {
        let logins = []
        data.map(item => {
          logins.push(item.locationLogin)
        })
        return logins.toString().split(',').join('<br />')
      } else {
        return '-'
      }
    },
    getTransactionId(data) {
      if (data && data.length) {
        let transactions = []
        data.map(item => {
          transactions.push(item.number)
        })
        return transactions.toString().split(',').join('<br />')
      } else {
        return '-'
      }
    },
    async sortColumnData(data) {
      this.sortColumn(data)
      await this.getBillListWrapper(this.filterData)
    },
    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getClientBillsSettings()
      this.createColumnConfigData()
      this.hideShowColumns()
    },
    async selectAction(event, data, actionName) {
      const invoice = data.rowData
      const invoiceId = data.rowData.id
      const clientId = data.rowData.client?.id

      switch (actionName) {
        case 'viewBill': {
          window.open(`${process.env.VUE_APP_API_URL}invoices/view/${invoiceId}?type=1`, '_blank');
          break
        }
        case 'editClient': {
          this.resetDisableTabs()
          this.financeResetState()
          this.setClientId(clientId)
          this.setBillNumber(data.rowData.number)
          this.setDisableButton(false)
          this.personalInfoReset()
          this.setDataClient({ 
            isClientEdit: true,
            clientProviderId: null
          })
          this.setContactMutation({
            emailSection: true,
            phoneSection: true,
            isEditMode: false,
          })

          this.resetBalanceManage()
          this.resetCreditData()
          this.balanceCreditSetData({ mainBalance: 0, dept: 0 })

          // get client data
          await this.getClientData(clientId).then(r => {
            this.setDataClient({ clientProviderId: r?.provider?.id })
            this.setPersonalInfo({ ...r.personalInfo, provider: r.provider.id }) // for personal info tab
            this.setContactData({ emails: r.emails, phones: r.phones })               // for contact tab
            r.addresses.length ? this.setAddresses({ addresses: r.addresses }) : '' // for addresses tab

            /* finance tab */
            let finance = {
              currency: null,
              priceGroup: null,
              paymentForPackages: null,
              paymentForDevice: null,
              isPaperlessBilling: r?.personalInfo.isPaperlessBilling
            } // TODO hardcoded.

            let paymentForPackagesObj = null
            r.finance ? finance = { ...r.finance } : this.financeSetData({ showFinanceForm: true })
            paymentForPackagesObj = r.finance?.forPackages
            
            this.setFinanceFormData(finance)
            this.financeSetData({ paymentForPackagesObj })
          })

          /** for Payment method, Balance/credit, Location tabs **/
          await Promise.all([
            this.getClientPaymentMethod({ clientId }),
            this.getBalanceCredit(),
            this.getLocations({ clientId })
          ])

          this.openClientsModal()
          this.setShowSaveBtn(false)
          this.setIsAddClient(false)
          break
        }
        case 'clientOrders': {
          console.log('clientOrders')
          break
        }
        case 'pay': {
          await this.getInvoice(invoice.id).then((r) => {
            if (r) this.openCheckoutModal()
          })
          break
        }
        case 'refund': {
          console.log('refund')
          break
        }
        case 'changeSendMethod': {
          console.log('changeSendMethod')
          break
        }
        case 'sentHistory': {
          console.log('sentHistory')
          break
        }
        case 'separateCredit': {
          console.log('separateCredit')
          break
        }
        case 'sendNow': {
          await this.sendIncoices([invoiceId]).then(response => {
            //After send invoice, create toast for show message
            this.createToastMessage( { success: true, message: response.message})
          }).catch((response) => {
            //After send invoice, create toast for show message
            this.createToastMessage({ success: false, message: response?.response?.data?.message ?? response.message})
          })
          break
        }
        case 'cancelNow': {
          await this.cancelIncoices([invoiceId]).then(response => {
            //After cancel invoice, create toast for show message
            this.createToastMessage( { success: true, message: response.message})
          }).catch((response) => {
            //After cancel invoice, create toast for show message
            this.createToastMessage({ success: false, message: response?.response?.data?.message ?? response.message})
          })
          break
        }
      }
    },

    async exportData() {
      this.filterClientBills();
      await this.getBillListWrapper({ ...this.filterData, excel: true })
      this.resetSelectedList()
    },

    hideShowColumns() {
      if (this.filterModel.sentUnsent === false) {
        this.columnConfigData.map((item) => {
          if (
            item.key === 'printDate' || 
            item.key === 'transactionId' || 
            item.key === 'paymentActionTime' ||
            item.key === 'autoPayment' || 
            item.key === 'paymentMethod' || 
            item.key === 'lastSentTime' || 
            item.key === 'billSentMethod'
          ) {
            item.isShow = false
            item.customUnshow = true
            item.disabled = true
          }
        })
      } else {
        this.columnConfigData.map((item) => {
          if (
            item.key === 'printDate' || 
            item.key === 'transactionId' || 
            item.key === 'paymentActionTime' ||
            item.key === 'autoPayment' || 
            item.key === 'paymentMethod' || 
            item.key === 'lastSentTime' || 
            item.key === 'billSentMethod'
          ) {
            item.isShow = true
            item.customUnshow = false
            item.disabled = false
          }
        })
      }
    },

    showHiddenColumns() {
      this.defaultSettings.forEach((item) => {
        if (
         (item.key === 'printDate' ||
          item.key === 'transactionId' ||
          item.key === 'paymentActionTime' ||
          item.key === 'autoPayment' ||
          item.key === 'paymentMethod' ||
          item.key === 'lastSentTime' ||
          item.key === 'billSentMethod') && 
          item.customUnshow

        ) {
          item.isShow = true
          item.customUnshow = false
          item.disabled = false
        }
      })
    },

    createToastMessage(response) {
      const toastOptions = {
        msg: response.message,
        position: "toast-bottom-right",
        defaultTimeout: 500
      }

      if (response.success) {
        toastOptions.type = "success"
        toastOptions.style = { 
          backgroundColor: "#01B2B8", 
          width: "max-content", 
          textTransform: "capitalize",
          opacity: '1'
        }

        this.$toastr.Add(toastOptions);
      } else {
        toastOptions.type = "error",
        toastOptions.style = { 
          backgroundColor: "red", 
          width: "max-content", 
          textTransform: "capitalize",
          opacity: '1'
        }

        this.$toastr.Add(toastOptions);
      }
    },

    hoverColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: false })
        this.shippingsSetData({ showColumnConfig: false })
        this.clientActivitySetData({ showColumnConfig: false })
      }
    },

    leaveColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: true })
        this.shippingsSetData({ showColumnConfig: true })
        this.clientActivitySetData({ showColumnConfig: true })
      }
    },
  }
}
