<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.sentUnsent"
        :items="sentUnsentList"
        :clear="true"
        selected-text="name"
        label="Sent/Not Sent"
        @emitFunc="filterData('sentUnsent')"
      />
    </div>  

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.providerId"
        :clear="true"
        :items="resellersList"
        selected-text="name"
        label="Select Provider"
        @emitFunc="filterData('providerId')"
      />
    </div>

    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.subscriptionAmount"
        :min="filterRangeData.subscriptionAmount[0]"
        :max="filterRangeData.subscriptionAmount[1]"
        @updateRange="filterData"
        @clearData="resetData('subscriptionAmount')"
        label="Subscription amount"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.dateForPay"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        locale="en"
        @input="filterData"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="ott-date-picker-cont Ott-input-class:">
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.start"
                      v-on="inputEvents.start"
                      outlined
                      dense
                      height="38"
                      label="Start Pay"
                  />
                  <span
                      class="P-date-icon neutral--text"
                      @click="resetData('dateForPay')"
                      :class="
                        !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                  ></span>
              </div>
              <span class="pick-to neutral--text">To</span>
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.end"
                      v-on="inputEvents.end"
                      outlined
                      dense
                      height="38px"
                      label="End Pay"
                  />
                  <span
                      @click="resetData('dateForPay')"
                      :class="
                          !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                      class="P-date-icon neutral--text"
                  ></span>
              </div>
          </div>
        </template>
      </DatePicker>
    </div>
    <!--    <div class="P-input-block">-->
    <!--      <ott-select-->
    <!--        label="Auto Payments"-->
    <!--        :items="clientBillsModule.autoPaymentList"-->
    <!--        :persistentHint="true"-->
    <!--        :clear="true"-->
    <!--        selected-text="name"-->
    <!--        v-model="filterModel.autoPayment"-->
    <!--        @emitFunc="filterData('autoPayment')"-->
    <!--      >-->
    <!--      </ott-select>-->
    <!--      <pre>{{filterModel.autoPayment}}</pre>-->
    <!--    </div>-->
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.paymentStatus"
        :items="clientBillsModule.paymentStatusList"
        :persistentHint="true"
        :clear="true"
        :multiple="true"
        selected-text="name"
        label="Payment Status"
        @emitFunc="filterData('paymentStatus')"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.billSentMethod"
        :items="clientBillsModule.billSentMethodList"
        :persistentHint="true"
        :clear="true"
        :multiple="true"
        @emitFunc="filterData('billSentMethod')"
        selected-text="name"
        label="Bill Sent Method "
      >
      </ott-select>
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.dateForBillSent"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        locale="en"
        @input="filterData"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="ott-date-picker-cont Ott-input-class:">
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.start"
                      v-on="inputEvents.start"
                      outlined
                      dense
                      height="38"
                      label="Start Bill Sent"
                  />
                  <span
                      class="P-date-icon neutral--text"
                      @click="resetData('dateForBillSent')"
                      :class="
                        !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                  ></span>
              </div>
              <span class="pick-to neutral--text">To</span>
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.end"
                      v-on="inputEvents.end"
                      outlined
                      dense
                      height="38px"
                      label="End Bill Sent"
                  />
                  <span
                      @click="resetData('dateForBillSent')"
                      :class="
                          !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                      class="P-date-icon neutral--text"
                  ></span>
              </div>
          </div>
        </template>
      </DatePicker>
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList from "./SelectedFiltersList";

export default {
  name: "FilterData",
  data() {
    return {
      sentUnsentList: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Sent',
          value: true
        },
        {
          name: 'Not Sent',
          value: false
        }
      ]
    }
  },
  components: { OttRangeSelect, OttSelect, OttCheckbox, OttInput, DatePicker, SelectedFiltersList },
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  computed: {
    ...mapState({
      filterModel: (state) => state.clientBillsModule.filterModel,
      filterRangeData: (state) => state.clientBillsModule.filterRangeData,
      resellersList: (state) => state.providers.resellersList,
      clientBillsModule: (state) => state.clientBillsModule,
      filter: state => state.clientBillsModule.filterData,
    }),
  },

  methods: {
    ...mapActions({
      getBillsList: 'clientBillsModule/getBillsList'
    }),
    ...mapMutations({
      filterClientBills: 'clientBillsModule/filterClientBills',
      setFilterPage: 'clientBillsModule/setFilterPage',
      sortColumn: 'clientBillsModule/sortColumn',
    }),

    async filterData(type) {
      this.setFilterPage(1)
      this.filterClientBills()
      if (!this.filterModel.search && type === "search") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.dateForPayStart && type === "dateForPayStart") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.dateForPayEnd && type === "dateForPayEnd") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.autoPayment && type === "autoPayment") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.paymentStatus.length && type === "paymentStatus") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.paymentActonBy && type === "paymentActonBy") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.paymentMethod.length && type === "paymentMethod") {
        this.filterModel.resetCurrentData(type);
      }
      if (
        !this.filterModel.dateForPaymentStart &&
        type === "dateForPaymentStart"
      ) {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.dateForPaymentEnd && type === "dateForPaymentEnd") {
        this.filterModel.resetCurrentData(type);
      }
      if (
        !this.filterModel.billSentMethod.length &&
        type === "billSentMethod"
      ) {
        this.filterModel.resetCurrentData(type);
      }
      if (
        !this.filterModel.dateForBillSentStart &&
        type === "dateForBillSentStart"
      ) {
        this.filterModel.resetCurrentData(type);
      }
      if (
        !this.filterModel.dateForBillSentEnd &&
        type === "dateForBillSentEnd"
      ) {
        this.filterModel.resetCurrentData(type);
      }
      if (this.filterModel.sentUnsent === null && type === "sentUnsent") {
        this.filterModel.resetCurrentData(type);
      }
      if (this.filterModel.providerId === null && type === "providerId") {
        this.filterModel.resetCurrentData(type);
      }

      this.filterModel.getFilterCount();
      this.sortColumn();
      await this.getBillsList(this.filter)
      this.$emit('resetSelectedList')
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterClientBills()
      this.filterModel.getFilterCount();
      await this.getBillsList(this.filter)
      this.$emit('resetSelectedList')
    },

    dateHidePopover(type) {
      this.$refs[type].hidePopover();
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom: 20px;
}

.P-ml-20 {
  margin-left: 20px;
}

.P-checkboxes {
  margin-bottom: 10px;
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 0;
  }
}
</style>
